<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-class-id="lesson.class"
  >
    <div
      class="page-header"
      :data-class-id="lesson.class"
    >
      <router-link to="/ccmp/lessons" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">Class {{ lesson.class }}</span>
        <span class="page-header__title">{{ lesson.title }}</span>
      </div>
    </div>

    <div class="page-container-alt">
      <h1 class="page-title">CCMP PRO Content</h1>

      <router-link v-if="!isCcmpPro" to="/unlock-pro" class="button-secondary">
        <i class="material-icons">shopping_cart</i> Unlock CCMP PRO
      </router-link>

      <!-- CCMP PRO not activated -->
      <div v-if="!isCcmpPro">
        <p>If you sign up for <a href="https://jtask.com/ccmp-training/" title="jTask CCMP Training" target="_blank">jTask CCMP
        Training</a>, the PRO version of the webapp is included free of charge.</p>
        <p>PRO content is derived from the ACMP®️ Standard for Change Management©️ and includes:</p>
        <ul class="features-list">
          <li>ACMP Progress Group, Steps, Purpose Statements, Input, and Output content.</li>
          <li>jTask content to provide deeper insight into how to use the Standard.</li>
          <li>jTask notes and templates to support important change management process steps.</li>
          <li>jTask top knowledge base questions such as “How you can build your own change management methodology or playbook” and “How to build a change agent network and identify change agents?”.</li>
          <li>jTask CCMP Training courses quizzes (110 questions).</li>
          <li>ACMP Code of Ethics check.</li>
        </ul>
      </div>

      <!-- CCMP PRO activated -->
      <div v-if="isCcmpPro">
        <a :href="lesson.quizUrl" target="_blank" class="button-link" :data-class-id="lesson.class">
          <i class="material-icons">question_answer</i>
          <span>Class {{ lesson.class }} - Quiz</span>
          <i class="material-icons icon-right">keyboard_arrow_right</i>
        </a>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'CcmpPro',

  props: ['id'],

  computed: {
    lesson () {
      return this.$store.state.ccmpData.lessons.find((lesson) => {
        return lesson.id === this.id
      })
    },

    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    }
  },

  created () {
  },

  methods: {

    formatTopicId (id) {
      return id
    },

    formatObjectiveId (id) {
      return id
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;

    &[data-class-id="1"] {
      background: $orange-light;
    }

    &[data-class-id="2"] {
      background: $jtask-blue-light;
    }

    &[data-class-id="3"] {
      background: $blue-light;
    }

    &[data-class-id="4"] {
      background: $green-light;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 0;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
  }

  .button-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 11px 20px;

    i {
      color: #fff;
      margin-right: 10px;
    }

    .icon-right {
      position: absolute;
      right: 25px;
    }

  }

  .features-list {
    padding-left: 30px;
    margin-bottom: 30px;
  }

</style>
